import React from "react"
import CardLink from "../components/card"
import _ from "lodash"

const Collection = ({sectionTitle, items, columnsCount, mobileColumnsCount, hideSubtitle}) => (
    <section className="section">
        <div className="title has-text-centered is-capitalized">{ _.upperCase(_.replace(sectionTitle, '-', ' '))}</div>
            <div className={'columns is-multiline' +  (_.isEmpty(mobileColumnsCount) ? '' : (` is-mobile`))}>
            {
                items.map(item => 
                (
                    <div key={ `${item.url}-div`} className={'column' + (_.isEmpty(columnsCount) ? '' : (` is-${12/columnsCount}-desktop`)) + (_.isEmpty(mobileColumnsCount) ? '' : (` is-${12/mobileColumnsCount}-mobile`))} >
                        <CardLink 
                            key={item.url}
                            title={item.title}
                            excerpt={item.excerpt}
                            subtitle={item.subtitle} 
                            url={ `${item.url}`}
                            featuredImage={item.featuredImage}
                            hideSubtitle={hideSubtitle}
                        />
                    </div>  
                )
                )
            }
            </div>
    </section>
    )

export default Collection;