import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Collection from "../components/collection"

import Layout from "../components/layout"
import SEO from "../components/seo"

const query = graphql`query Portfolio {
    allMarkdownRemark(filter: {fields: {collection: {in: ["rim-projects","ibm-projects","festo-projects", "exact-projects"]}}}) {
      edges {
        node {
            id
            fields {
                slug
            }
            frontmatter{
              title,
              subtitle,
              featuredImage {
                childImageSharp {
                  fluid(maxWidth: 530, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
      }
    }
  }
  `

 const PortfolioPage = () => (
    <StaticQuery
      query={query}
      render={data => {
        let items = data.allMarkdownRemark.edges.map(({node: page}) => {
            return {
              key: page.fields.slug,
              title: page.frontmatter.title,
              subtitle: page.frontmatter.subtitle,
              url: page.fields.slug, 
              featuredImage: page.frontmatter.featuredImage
          }});
        return (
            <Layout>
                <SEO title="Portfolio" />
                <div className="container">
                    <Collection sectionTitle="Portfolio" items={items} columnsCount="4" mobileColumnsCount="2" hideSubtitle="false"></Collection>
                </div>
            </Layout>
      )}}
    />
  )
export default PortfolioPage